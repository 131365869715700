import React,{Component} from "react";
import '../infolist/Info.css'
export default class Info extends Component{

    render(){
        return(
            <div className="Item">
                <p className="Info1">{this.props.item.name} </p>
                <p className="Info2">{this.props.item.name} </p>
                <p className="Info3">{this.props.item.name} </p>
            </div>
        )
    }
}