import React,{Component} from "react";
import { Form, Input, Button, Image, message } from 'antd';
import { UserOutlined, LockOutlined, BarcodeOutlined } from '@ant-design/icons';
import '../user/login.css'
import localStorage from "localStorage";
import axios from 'axios'
import PubSub from 'pubsub-js'
export default class Login extends Component{
    onFinish (values) {
        console.log('Success:', values);
        axios.get('https://sbkiot.com/users/login?name='+values.username+'&pwd='+values.password).then(res => {
            localStorage.setItem('accessToken',res.data.token);
            PubSub.publish('token',res.data.token);
        })
      };
    render(){
        return (
            <div>
                {/* <div className="avatar_box">
                    <img src="./logo192.png" alt="" />
                </div> */}
                <Form name="normal_login" className="login-form" onFinish={this.onFinish}>
                    <Form.Item name="username" rules={[{ required: true, message: '请输入账号' }]} className="username" >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="账号" size="large"/>
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: '请输入密码' }]} >
                        <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="密码" size="large" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="Login-form-button" size="large">登录</Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}