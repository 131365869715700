
import './App.css';
import './AppH5.css';
import Clock from './component/clock/Clock'
import InfoList from './component/infolist/InfoList'
import Num from './component/num/Num'
import  ThreeFiberGltf from './component/threefiber/ThreeFiberGltf'
import Login from './component/user/login';
import Echart from './component/echarts/Echart';
import PieEchart from './component/echarts/PieEchart';
import BarEchart from './component/echarts/BarEchart';
import RadarEchart from './component/echarts/RadarEchart';
import RateEchart from './component/echarts/RateEchart';
import FunnelEchart from './component/echarts/FunnelEchart';
import localStorage from "localStorage";
import React,{Component} from "react";
import PubSub from 'pubsub-js'
import chartbg from '../src/images/chartbg.png'
import bg from '../src/images/bg.png'
import title from '../src/images/title.png'
import title_h5 from '../src/images/title-h5.png'
import size from '../src/images/size.png'
import pingmi from '../src/images/pingmi.png'
import time from '../src/images/time.png'
import tian from '../src/images/tian.png'
import device from '../src/images/device.png'
import ge from '../src/images/ge.png'
import baojing from '../src/images/baojing.png'
import centertitle from '../src/images/centertitle.png'
import center from '../src/images/center.png'
import bottom from '../src/images/bottom.png'
import l1 from '../src/images/charttitle/l1.png'
import l2 from '../src/images/charttitle/l2.png'
import l3 from '../src/images/charttitle/l3.png'
import r1 from '../src/images/charttitle/r1.png'
import r2 from '../src/images/charttitle/r2.png'
import r3 from '../src/images/charttitle/r3.png'
export default class App extends Component{
  constructor(props){
    super(props);
    this.state = {
      token: localStorage.getItem("accessToken"),
      devicenum: -1,
      size:-1,
      days:-1,
      warningnum:-1,
      windowHeight:window.innerHeight,
      windowWidth:window.innerWidth
    }
}
componentDidMount(){
  // this.getDeviceList();
  PubSub.subscribe('token',(msg,data) =>{
    console.log(data)
      this.setState({token:data});
  })
  PubSub.subscribe('userinfo',(msg,data) =>{
    console.log(data.createtime);
    var days = parseInt(Math.abs(new Date()-new Date(data.createtime)) / (1000 * 60 * 60 * 24));
    this.setState({
      devicenum: data.devicenum,
      size:data.size,
      days:days,
      warningnum:data.warningnum
    });
  })
  PubSub.subscribe('windowH&W',(msg,data) =>{
      this.setState({windowHeight:data.windowHeight,windowWidth:data.windowWidth});
  })
}
// function App() {
  render(){
  return (
    this.state.windowHeight < this.state.windowWidth? (!this.state.token || this.state.token ==='null' ? <div className="App-login"> <Login /></div> :
    <div className="App">
        
        <div className='App-bg' >
          <img  style={{height:"100%",width:"100%"}} src = {bg}/>
        </div>
        <div className='App-title-bg' >
          <img  style={{height:"100%",width:"100%"}} src = {title}/>
        </div>
        <div className='App-center-bg' >
          <img  style={{height:"100%",width:"100%"}} src = {center}/>
        </div>
        <div className='App-center-title' >
          <img  style={{height:"100%",width:"100%"}} src = {centertitle}/>
        </div>
        <div className='App-bottom-bg' >
          <img  style={{height:"100%",width:"100%"}} src = {bottom}/>
        </div>
        <div className='App-size-num'>
          <img src = {size} style={{height:"2vh",width:'6vw',marginRight:"1vw"}}/>
          <Num num={this.state.size} width='2vw' marginRight='0.2vw'/>
          <img src = {pingmi} style={{height:"1.5vh",width:'3vw',marginLeft:"1vw"}}/>
        </div>
        <div className='App-day-num'>
          <img src = {time} style={{height:"2vh",width:'6vw',marginRight:"1vw"}}/>
          <Num num={this.state.days} width='2vw' marginRight='0.2vw'/>
          <img src = {tian} style={{height:"1.5vh",width:'1.5vw',marginLeft:"1vw"}}/>
        </div>
        <div className='App-device-num'>
          <img src = {device} style={{height:"2vh",width:'6vw',marginRight:"1vw"}}/>
          <Num num={this.state.devicenum} width='2vw' marginRight='0.2vw'/>
          <img src = {ge} style={{height:"1.5vh",width:'1.5vw',marginLeft:"1vw"}}/>
        </div>
        <div className='App-warning-num'>
          <img src = {baojing} style={{height:"2vh",width:'6vw',marginRight:"1vw"}}/>
          <Num num={this.state.warningnum} width='2vw' marginRight='0.2vw'/>
          <img src = {ge} style={{height:"1.5vh",width:'1.5vw',marginLeft:"1vw"}}/>
        </div>
        <div className='App-infolist'>
          <InfoList/>
        </div>
        <div  className='App-map'>
            <ThreeFiberGltf/>
        </div>
        <div className='App-rateEchart-bg' >
          <img  style={{height:"100%",width:"100%"}} src = {chartbg}/>
        </div>
        <div className='App-rateEchart-title' >
          <img  style={{height:"100%",width:"100%"}} src = {l1}/>
        </div>
        <div className='App-rateEchart'>
          <RateEchart/>
        </div>
        <div className='App-funnelEchart-bg' >
          <img  style={{height:"100%",width:"100%"}} src = {chartbg}/>
        </div>
        <div className='App-funnelEchart-title' >
          <img  style={{height:"100%",width:"100%"}} src = {r1}/>
        </div>
        <div className='App-funnelEchart'>
          <FunnelEchart/>
        </div>
        <div className='App-lineEchart-bg' >
          <img  style={{height:"100%",width:"100%"}} src = {chartbg}/>
        </div>
        <div className='App-lineEchart-title' >
          <img  style={{height:"100%",width:"100%"}} src = {l2}/>
        </div>
        <div className='App-lineEchart'>
          <Echart/>
        </div>
        <div className='App-pieEchart-bg' >
          <img  style={{height:"100%",width:"100%"}} src = {chartbg}/>
        </div>
        <div className='App-pieEchart-title' >
          <img  style={{height:"100%",width:"100%"}} src = {r2}/>
        </div>
        <div className='App-pieEchart'>
          <PieEchart/>
        </div>
        <div className='App-barEchart-bg' >
          <img  style={{height:"100%",width:"100%"}} src = {chartbg}/>
        </div>
        <div className='App-barEchart-title' >
          <img  style={{height:"100%",width:"100%"}} src = {l3}/>
        </div>
        <div className='App-barEchart'>
          <BarEchart/>
        </div>
        <div className='App-radarEchart-bg' >
          <img  style={{height:"100%",width:"100%"}} src = {chartbg}/>
        </div>
        <div className='App-radarEchart-title' >
          <img  style={{height:"100%",width:"100%"}} src = {r3}/>
        </div>
        <div className='App-radarEchart'>
          <RadarEchart/>
        </div>
        
      {/* </main> */}
    </div>):
    (!this.state.token || this.state.token ==='null' ? <div className="App-login-H5"> <Login /></div> :
    <div className="App">
        
    <div className='App-bg-H5' >
      <img  style={{height:"100%",width:"100%"}} src = {bg}/>
    </div>
    <div className='App-title-bg-H5' >
      <img  style={{height:"100%",width:"100%"}} src = {title_h5}/>
    </div>
    <div className='App-center-bg-H5' >
      <img  style={{height:"100%",width:"100%"}} src = {center}/>
    </div>
    <div className='App-center-title-H5' >
      <img  style={{height:"100%",width:"100%"}} src = {centertitle}/>
    </div>
    <div className='App-bottom-bg-H5' >
      <img  style={{height:"100%",width:"100%"}} src = {bottom}/>
    </div>
    <div className='App-size-title-H5' >
      <img src = {size} style={{height:"100%",width:"100%"}}/>
    </div>
    <div className='App-size-num-H5' >
      <Num num={this.state.size} width='6vw' marginRight='0.5vw'/>
      <img src = {pingmi} style={{height:"1.5vh",width:'6vw',marginLeft:"1vw"}}/>
    </div>
    <div className='App-day-title-H5' >
      <img src = {time} style={{height:"100%",width:"100%"}}/>
    </div>
    <div className='App-day-num-H5' >
      <Num num={this.state.days} width='6vw' marginRight='0.5vw'/>
      <img src = {tian} style={{height:"1.5vh",width:'3vw',marginLeft:"1vw"}}/>
    </div>
    <div className='App-device-title-H5' >
      <img src = {device} style={{height:"100%",width:"100%"}}/>
    </div>
    <div className='App-device-num-H5' >
      <Num num={this.state.devicenum} width='6vw' marginRight='0.5vw'/>
      <img src = {ge} style={{height:"1.5vh",width:'3vw',marginLeft:"1vw"}}/>
    </div>
    <div className='App-warning-title-H5' >
      <img src = {baojing} style={{height:"100%",width:"100%"}}/>
    </div>
    <div className='App-warning-num-H5' >
      <Num num={this.state.warningnum} width='6vw' marginRight='0.5vw'/>
      <img src = {ge} style={{height:"1.5vh",width:'3vw',marginLeft:"1vw"}}/>
    </div>
    <div className='App-infolist-H5' >
      <InfoList/>
    </div>
    <div  className='App-map-H5' >
        <ThreeFiberGltf/>
    </div>
    <div className='App-rateEchart-bg-H5' >
      <img  style={{height:"100%",width:"100%"}} src = {chartbg}/>
    </div>
    <div className='App-rateEchart-title-H5' >
      <img  style={{height:"100%",width:"100%"}} src = {l1}/>
    </div>
    <div className='App-rateEchart-H5' >
      <RateEchart/>
    </div>
    <div className='App-funnelEchart-bg-H5' >
      <img  style={{height:"100%",width:"100%"}} src = {chartbg}/>
    </div>
    <div className='App-funnelEchart-title-H5' >
      <img  style={{height:"100%",width:"100%"}} src = {r1}/>
    </div>
    <div className='App-funnelEchart-H5' >
      <FunnelEchart/>
    </div>
    <div className='App-lineEchart-bg-H5' >
      <img  style={{height:"100%",width:"100%"}} src = {chartbg}/>
    </div>
    <div className='App-lineEchart-title-H5' >
      <img  style={{height:"100%",width:"100%"}} src = {l2}/>
    </div>
    <div className='App-lineEchart-H5' >
      <Echart/>
    </div>
    <div className='App-pieEchart-bg-H5' >
      <img  style={{height:"100%",width:"100%"}} src = {chartbg}/>
    </div>
    <div className='App-pieEchart-title-H5' >
      <img  style={{height:"100%",width:"100%"}} src = {r2}/>
    </div>
    <div className='App-pieEchart-H5' >
      <PieEchart/>
    </div>
    <div className='App-barEchart-bg-H5' >
      <img  style={{height:"100%",width:"100%"}} src = {chartbg}/>
    </div>
    <div className='App-barEchart-title-H5' >
      <img  style={{height:"100%",width:"100%"}} src = {l3}/>
    </div>
    <div className='App-barEchart-H5' >
      <BarEchart/>
    </div>
    <div className='App-radarEchart-bg-H5' >
      <img  style={{height:"100%",width:"100%"}} src = {chartbg}/>
    </div>
    <div className='App-radarEchart-title-H5' >
      <img  style={{height:"100%",width:"100%"}} src = {r3}/>
    </div>
    <div className='App-radarEchart-H5' >
      <RadarEchart/>
    </div>
    </div>)
  );
}
}
// export default App;
