import React,{Component} from "react";
import ReactEcharts from "echarts-for-react"
import PubSub from 'pubsub-js';
export default class RateEchart extends Component{
  constructor(props){
    super(props);
    this.state = {
      option:null
    };
  }
  componentDidMount(){
    let myChart = this.echarts&&this.echarts.getEchartsInstance(); 
    //拿到实例后 通过getEchartsInstance()，在EchartsReactCore里ECharts实例
    //注意EchartsReactCore实例和ECharts实例的区别 下面附上图片
    //监听窗口onresize变化  这里有两种写法 推荐使用addEventListener写法 第一种方法绑定多个resize事件 
    //会被覆盖
    //这里只是简写 这里可以把函数提出来
    //window.onresize = ()=> {
    // myChart&&myChart.resize();
    // };
    window.addEventListener('resize',()=>{
      myChart&&myChart.resize();
    })
      PubSub.subscribe('userinfo',(msg,data) =>{
          this.setState({option:{
            series: [
              {
                type: 'gauge',
                startAngle: 180,
                endAngle: 0,
                min: 0,
                max: 1,
                splitNumber: 4,
                axisLine: {
                  lineStyle: {
                    width: 6,
                    color: [
                      [0.25, '#FF6E76'],
                      [0.5, '#FDDD60'],
                      [0.75, '#58D9F9'],
                      [1, '#7CFFB2']
                    ]
                  }
                },
                pointer: {
                  icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                  length: '12%',
                  width: 8,
                  offsetCenter: [0, '-35%'],
                  itemStyle: {
                    color: 'auto'
                  }
                },
                axisTick: {
                  length: 12,
                  lineStyle: {
                    color: 'auto',
                    width: 2
                  }
                },
                splitLine: {
                  length: 5,
                  lineStyle: {
                    color: 'auto',
                    width: 2
                  }
                },
                axisLabel: {
                  color: '#FF0000',
                  fontSize: 10,
                  distance: -30,
                  formatter: function (value) {
                    if (value === 0.875) {
                      return 'A';
                    } else if (value === 0.625) {
                      return 'B';
                    } else if (value === 0.375) {
                      return 'C';
                    } else if (value === 0.125) {
                      return 'D';
                    }
                    return '';
                  }
                },
                title: {
                  offsetCenter: [0, '-20%'],
                  fontSize: 10
                },
                detail: {
                  fontSize: 20,
                  offsetCenter: [0, '0%'],
                  valueAnimation: true,
                  formatter: function (value) {
                    return Math.round(value * 100) + '分';
                  },
                  color: 'auto'
                },
                data: [
                  {
                    value: data.score,
                    name: ''
                  }
                ]
              }
            ]
          }});
      })
  }
    render(){
        return(
          this.state.option==null?null:<div style={{width:'100%',height:'100%'}}>
            <ReactEcharts  ref={(e) => { this.echarts = e;}} option={this.state.option} style={{width:'100%',height:'100%'}}/>
          </div>
        )
    }
}