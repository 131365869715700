import React,{Component} from "react";
import ReactEcharts from "echarts-for-react"
import PubSub from 'pubsub-js';
export default class RadarEchart extends Component{
  constructor(props){
    super(props);
    this.state = {
      option:null
    };
  }
  componentDidMount(){
    let myChart = this.echarts&&this.echarts.getEchartsInstance(); 
    //拿到实例后 通过getEchartsInstance()，在EchartsReactCore里ECharts实例
    //注意EchartsReactCore实例和ECharts实例的区别 下面附上图片
    //监听窗口onresize变化  这里有两种写法 推荐使用addEventListener写法 第一种方法绑定多个resize事件 
    //会被覆盖
    //这里只是简写 这里可以把函数提出来
    //window.onresize = ()=> {
    // myChart&&myChart.resize();
    // };
    window.addEventListener('resize',()=>{
      myChart&&myChart.resize();
    })
      PubSub.subscribe('userinfo',(msg,data) =>{
          var values = [];
          data.enviroments.map(item =>{
            values.push(item.score);
          })
          this.setState({option:
            {
              tooltip: {
                trigger: 'item'
              },
              radar: {
                // shape: 'circle',
                  radius: ["0%", "50%"],
                indicator: data.enviroments,
              },
              series: [
                {
                  name: 'Budget vs spending',
                  type: 'radar',
                  data: [
                    {
                      value: values,
                      name: 'Allocated Budget'
                    }
                  ],
                blur: {
                  areaStyle: {
                    shadowBlur: 1.5,
                    shadowColor: "rgba(204, 90, 90, 1)",
                    color: "rgba(147, 24, 24, 1)"
                  }
                },
                areaStyle: {
                  color: "rgba(221, 93, 93, 1)"
                },
                }
              ]
          }});
      })
  }
    render(){
        return(
                this.state.option==null?null:<div style={{width:'100%',height:'100%'}}>
                <ReactEcharts  ref={(e) => { this.echarts = e;}} option={this.state.option} style={{width:'100%',height:'100%'}}/>
              </div>
        )
    }
}