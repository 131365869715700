import React,{Component} from "react";
import ReactEcharts from "echarts-for-react"
import PubSub from 'pubsub-js';
export default class FunnelEchart extends Component{
  constructor(props){
    super(props);
    this.state = {
      option:null
    };
  }
  componentDidMount(){
    let myChart = this.echarts&&this.echarts.getEchartsInstance(); 
    //拿到实例后 通过getEchartsInstance()，在EchartsReactCore里ECharts实例
    //注意EchartsReactCore实例和ECharts实例的区别 下面附上图片
    //监听窗口onresize变化  这里有两种写法 推荐使用addEventListener写法 第一种方法绑定多个resize事件 
    //会被覆盖
    //这里只是简写 这里可以把函数提出来
    //window.onresize = ()=> {
    // myChart&&myChart.resize();
    // };
    window.addEventListener('resize',()=>{
      myChart&&myChart.resize();
    })
      PubSub.subscribe('userinfo',(msg,data) =>{
          this.setState({option: {
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c}%'
            },
            series: [
              {
                name: 'Expected',
                type: 'funnel',
                left: '10%',
                width: '80%',
                label: {
                    fontSize:8,
                  formatter: '{b}Expected'
                },
                labelLine: {
                  show: false
                },
                itemStyle: {
                  opacity: 0.7
                },
                emphasis: {
                  label: {
                    fontSize:8,
                    position: 'inside',
                    formatter: '{b}Expected: {c}%'
                  }
                },
                data: [
                  { value: 60, name: 'Visit' },
                  { value: 40, name: 'Inquiry' },
                  { value: 20, name: 'Order' },
                  { value: 80, name: 'Click' },
                  { value: 100, name: 'Show' }
                ]
              },
              {
                name: 'Actual',
                type: 'funnel',
                left: '10%',
                width: '80%',
                maxSize: '80%',
                label: {
                    fontSize:8,
                  position: 'inside',
                  formatter: '{c}%',
                  color: '#fff'
                },
                itemStyle: {
                  opacity: 0.5,
                  borderColor: '#fff',
                  borderWidth: 2
                },
                emphasis: {
                  label: {
                    position: 'inside',
                    formatter: '{b}Actual: {c}%'
                  }
                },
                data: [
                  { value: 30, name: 'Visit' },
                  { value: 10, name: 'Inquiry' },
                  { value: 5, name: 'Order' },
                  { value: 50, name: 'Click' },
                  { value: 80, name: 'Show' }
                ],
                // Ensure outer shape will not be over inner shape when hover.
                z: 100
              }
            ]
          }});
      })
  }
    render(){
        return(
          this.state.option==null?null:<div style={{width:'100%',height:'100%'}}>
            <ReactEcharts  ref={(e) => { this.echarts = e;}} option={this.state.option} style={{width:'100%',height:'100%'}}/>
          </div>
        )
    }
}