import React,{Component} from "react";
import ReactEcharts from "echarts-for-react"
import PubSub from 'pubsub-js';
export default class PieEchart extends Component{
  constructor(props){
    super(props);
    this.state = {
      option:null
    };
  }
  componentDidMount(){
    let myChart = this.echarts&&this.echarts.getEchartsInstance(); 
    //拿到实例后 通过getEchartsInstance()，在EchartsReactCore里ECharts实例
    //注意EchartsReactCore实例和ECharts实例的区别 下面附上图片
    //监听窗口onresize变化  这里有两种写法 推荐使用addEventListener写法 第一种方法绑定多个resize事件 
    //会被覆盖
    //这里只是简写 这里可以把函数提出来
    //window.onresize = ()=> {
    // myChart&&myChart.resize();
    // };
    window.addEventListener('resize',()=>{
      myChart&&myChart.resize();
    })
      PubSub.subscribe('userinfo',(msg,data) =>{
          this.setState({option:
            {
              tooltip: {
                trigger: 'item'
              },
              grid: {
                height: '100%',
                bottom:0
              },
              series: [
                {
                  name: '',
                  type: 'pie',
                  height: 150,
                  radius: ['40%', '70%'],
                  avoidLabelOverlap: false,
                  label: {
                    show: false,
                    position: 'center'
                  },
                  emphasis: {
                    label: {
                      show: true,
                      fontSize: '20',
                      fontWeight: 'bold'
                    }
                  },
                  labelLine: {
                    show: false
                  },
                  data: [
                    { value: 70, name: '70%' },
                    { value: 30, name: '30%' },
                  ]
                }
              ]
            }});
      })
  }
    render(){
        return(
          this.state.option==null?null:<div style={{width:'100%',height:'100%'}}>
            <ReactEcharts  ref={(e) => { this.echarts = e;}} option={this.state.option} style={{width:'100%',height:'100%'}}/>
          </div>
        )
    }
}