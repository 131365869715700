/**
 * React+Three.js 三维地球
 */
 import '../threefiber/ThreeFiber.css';
 import React, { Component, Suspense, useRef} from 'react';
 import { Canvas, useLoader, useFrame } from '@react-three/fiber';
 import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
 import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
 import { OrbitControls } from '@react-three/drei';
 import { AxesHelper,Color } from 'three';
 import PubSub from 'pubsub-js';
 import axios from 'axios';
 import Loader from '../threefiber/Loader'
 
 var meshList = [];
 var userinfo;
 function MyMesh () {
    // const gltf = useLoader(GLTFLoader, 'https://bingning-resource.oss-cn-shanghai.aliyuncs.com/shibike/taicang.glb')
    const gltf = useLoader(GLTFLoader, userinfo.building.glb)
    return (
        <primitive object={gltf.scene} position={userinfo.building.position}/>
        // <primitive object={fbx} />
    )
 }
 function DemoMesh () {
    const ref = useRef();
    return(
        <mesh ref={ref} rotation={[0,0,0]}  position={userinfo.building.position}>
            <boxGeometry attach="geometry" args={[4,4,2]}/>
            <meshStandardMaterial attach="material"/>
        </mesh>)
 }
 function addMesh(device){
    const scale = [device.index[0] == 0? 0.05 : userinfo.building.size[0]/device.index[0],
    device.index[1] == 0? 0.05 : userinfo.building.size[1]/device.index[1],
    device.index[2] == 0? 0.05 : userinfo.building.size[2]/device.index[2]]
    if(device.wdts && device.wdts.length > 0)
        device.wdts.forEach((wdt,i) => {
            if(i< (device.index[0] != 0?device.index[0]:device.index[1])){
                var vals = wdt.val.split('#');
                vals.forEach((val,j) =>{
                    if(j< (device.index[1] != 0?device.index[1]:device.index[2])){
                        const data = {
                            id:device.deviceId+':'+wdt.id+':'+j,
                            val:val,
                            time:wdt.tsp,
                            status: '在线',
                            changeFlag:true,
                            x: (device.index[0] == 0 ? 0 : i * scale[0]) + device.offset[0],
                            y: (device.index[1] == 0 ? 0 : (device.index[0] == 0 ? i : j) * scale[1]) + device.offset[1],
                            z: (device.index[2] == 0 ? 0 : j * scale[2]) + device.offset[2],
                            // x: device.index[0] == 0 ? 0 : i * scale[0] - userinfo.building.size[0]/2,
                            // y: device.index[1] == 0 ? 0 : (device.index[0] == 0 ? i : j) * scale[1] - userinfo.building.size[1]/2,
                            // z: device.index[2] == 0 ? 0 : j * scale[2] - userinfo.building.size[2]/2,
                            args: scale
                        }
                        // console.log(data)
                        meshList.push(data);
                    }
                })
            }
            
        })
 }
 function setMesh(deviceId,data){
    if(data && data.length > 0){
        data.forEach((wdt,i) => {
            var vals = wdt.val.split('#');
            vals.forEach((val,j) =>{
                meshList.forEach((mesh,n) => {
                    if(mesh.id == deviceId+':'+wdt.id+':'+j){
                        meshList[n].time = wdt.tsp;
                        if(mesh.val != val){
                            meshList[n].val = val;
                            meshList[n].changeFlag = true;
                            // console.log(meshList[n])
                        }
                    }
                })
            })

        })

    }
 }
 function getMeshStatus(id){
    var status = {
        changeFlag: false,
        status: '掉线'
    }
    if(meshList && meshList.length > 0){
        meshList.forEach((mesh,i) => {
            if(mesh.id == id){
                if(mesh.changeFlag){
                    status.changeFlag = true;
                    mesh.changeFlag = false;
                }
                status.status = mesh.val;
            }
                return mesh;
        })
    }
    return status;
 }
 function OtherMesh(props){
    const ref = useRef();
    useFrame((state) => {
        const status = getMeshStatus(props.deviceId)
        if(status.changeFlag){
            if(status.status === '掉线'){
                ref.current.material.color.set(0xff0000);
            }else{
                var color = Number(status.status )
                color = new Color(0,color/1000,color/1000);
                ref.current.material.color.set(color);
            }
        }
    });
    // console.log(props);
    return(
        <mesh ref={ref} {...props}>
            {/* <planeGeometry attach="geometry" /> */}
            <boxGeometry attach="geometry" args={props.args}/>
            <meshStandardMaterial attach="material"/>
        </mesh>)
 }
 function subscribleMqtt(dataList){
    //引入mqtt
    const mqtt = require('mqtt')

    const MQTT_OPTIONS = {
        connectTimeout: 50000,
        clientId: 'mesh_'+Math.floor((Math.random()*10000)+1),
        // username: 'admin',
        // password: 'public',
    }

    const client = mqtt.connect("mqtt://47.103.89.45:2024", MQTT_OPTIONS)
        client.on("connect", () => {
            console.log('连接成功!');
            dataList.forEach(element => {
                if(element.deviceId){
                    client.subscribe(element.deviceId, (err) => {
                        if (!err) {
                            console.log(element.deviceId+'订阅成功!')
                        }
                    })
                }
                
            });
        }).on('reconnect', (err) => {
            console.log('正在重连...')
        }).on('end', (err) => {
            console.log('连接断开!')
        }).on("message", (topic, message) => {
            console.log("topic: ", topic)
            try {
                var data = JSON.parse(message);
                setMesh(topic,data.user);
                // console.log(topic,message)
            } catch (e) {
                console.log("Caught: " + e.message)
            }
        })
 }
 class ThreeFiberGltf extends Component{

    constructor(props){
        super(props);
        this.state = {
            dataList: []
        }
    }
    componentDidMount(){
        this.getDeviceList();
        window.addEventListener('resize',()=>{
            PubSub.publish('windowH&W',{windowHeight:window.innerHeight,windowWidth:window.innerWidth});
          })
    }
    getDeviceList(){
        axios.get('https://sbkiot.com/users/getDeviceList',{
            headers:{'token':localStorage.getItem("accessToken")}}).then(res => {
                if(res.data.code === 200){
                    userinfo = res.data.userinfo;
                    res.data.deviceList.forEach(device =>{
                        // console.log(device);
                        addMesh(device);
                    });
                    PubSub.publish('userinfo',userinfo);
                    this.setState({dataList:meshList});
                    subscribleMqtt(res.data.deviceList);
                }else if(res.data.code === 400){
                    localStorage.setItem("accessToken",null)
                    PubSub.publish('token','');
                    // this.forceUpdate();
                }
        })
    }
    render(){
        return(
            this.state.dataList.length>0 ? (
                <Canvas className="Fiber" >
                    <Suspense ref={this.suspense} fallback={<Loader/>}>
                        {/* <directionalLight color={'#fff'} intensity={1} position={[-3, 5, 5]} /> */}
                        {/* <primitive object={new AxesHelper(100)} /> */}
                        <ambientLight />
                        {userinfo.building.glb==''?<DemoMesh  />:<MyMesh ref={this.mymesh} />}
                        {
                        this.state.dataList.map(item =>{
                            return (
                                <OtherMesh args={item.args} 
                                position={[item.x,item.y,item.z]} 
                                deviceId={item.id}/>
                            )})
                        }
                        <OrbitControls  autoRotate={true}/>
                        {/* <color attach='background' args={['#aaa']} /> */}
                    </Suspense>
                </Canvas> ) : ( null )
        )
    }
 }
 
 export default ThreeFiberGltf;