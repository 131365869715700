import React,{Component} from "react";
import '../num/Num.css'
import png0 from '../../images/num/0.png'
import png1 from '../../images/num/1.png'
import png2 from '../../images/num/2.png'
import png3 from '../../images/num/3.png'
import png4 from '../../images/num/4.png'
import png5 from '../../images/num/5.png'
import png6 from '../../images/num/6.png'
import png7 from '../../images/num/7.png'
import png8 from '../../images/num/8.png'
import png9 from '../../images/num/9.png'


export default class Num extends Component{
    constructor(props){
        super(props);
    }

    render(){
        if(this.props.num == null) return null;
        var nums = [];
        var temp = this.props.num
        if(temp == 0) nums = [0];
        else
            while(temp > 0){
                switch(parseInt(temp%10)){
                    case 0:nums.push(png0);break;
                    case 1:nums.push(png1);break;
                    case 2:nums.push(png2);break;
                    case 3:nums.push(png3);break;
                    case 4:nums.push(png4);break;
                    case 5:nums.push(png5);break;
                    case 6:nums.push(png6);break;
                    case 7:nums.push(png7);break;
                    case 8:nums.push(png8);break;
                    case 9:nums.push(png9);break;
                }
                temp = Math.floor(temp / 10);
            }
            for(var i=0;i<nums.length/2;i++){
                var temp = nums[i];
                nums[i] = nums[nums.length -1 -i];
                nums[nums.length -1 -i] = temp;
            }
        return(
            nums.map((item) => {
            return (
                    <img src = {item} style={{height:"4vh",width:this.props.width,marginRight:this.props.marginRight}}/>
                );
            })
        )
    }
}