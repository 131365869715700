import React,{Component} from "react";
import '../infolist/InfoList.css'
import Info from './Info'
import PubSub from 'pubsub-js';


export default class InfoList extends Component{
    constructor(props){
        super(props);
        this.state = {
            infoList: [
                // {id:1,location:'101号房间',status:'正常'},
                // {id:2,location:'102号房间',status:'离线'},
                // {id:3,location:'103号房间',status:'异常'}
            ]
        }
    }
    componentDidMount(){
        PubSub.subscribe('userinfo',(msg,data) =>{
            this.setState({infoList:data.infos});
        })
    }

    render(){
        return(
            this.state.infoList.length ? (
                this.state.infoList.map((item) => {
                    return (
                        <Info item = {item} />
                      );
                    })
                  ) : null
        )
    }
}